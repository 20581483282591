<template>
  <v-card>
    <v-toolbar
      dense
      card
    >
      <v-icon :color="newClass">
        {{ newIcon }}
      </v-icon>
      <v-toolbar-title>Add New Numbers</v-toolbar-title>
    </v-toolbar>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        mask="#####"
        solo-inverted
        clearable
        box
      />
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="localSelections"
        :loading="loading"
        :pagination.sync="pagination"
        :rows-per-page-items="[5]"
        :search="search"
        class="px-1 pb-1"
        expand
        item-key="msisdn"
      >
        <template
          slot="headers"
          slot-scope="props"
        >
          <tr>
            <th width="48px" />
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column sortable',
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : '',
                header.align ? `text-xs-${header.align}` : ''
              ]"
              width="auto"
              @click="changeSort(header.value)"
            >
              <v-icon small>
                arrow_upward
              </v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template
          slot="items"
          slot-scope="props"
        >
          <tr
            :active="props.selected"
            @click="props.selected = props.expanded = !props.selected; lockMsisdn(props)"
          >
            <td>
              <v-checkbox
                :input-value="props.selected"
                primary
                hide-details
              />
            </td>
            <td class="text-xs-left">
              {{ props.item.msisdn }}
            </td>
            <!--td class="text-xs-right">{{ props.item.price }}</td-->
            <td class="text-xs-center">
              <v-icon>{{ exclusiveIcon(props.item.exclusive) }}</v-icon>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template
          slot="expand"
          slot-scope="props"
        >
          <v-card class="elevation-1 mx-2 mb-2 inner">
            <v-card-text>
              <v-progress-linear
                v-if="props.item.stage === 0"
                :indeterminate="true"
                height="2"
              />
              <v-container
                v-if="props.item.stage > 0"
                fluid
                grid-list-xs
              >
                <v-layout
                  v-if="props.item.stage === 1"
                  wrap
                  align-baseline
                >
                  <v-flex xs12>
                    <iccid
                      v-model="props.item.iccid"
                      :rules="iccidRules"
                      hint="Locate serial number on the simpack"
                      label="Enter serial number"
                      required
                      blank-sim
                    />
                  </v-flex>
                  <v-flex xs12>
                    <black-packages v-model="props.item.plan" />
                  </v-flex>
                  <!--v-flex v-if="props.item.price" xs12>
                    <sim-price
                      hint="Mark-up from bid price recommended"
                      label="Enter Msisdn price"
                      required
                      v-model="props.item.sellingPrice">
                    </sim-price>
                  </v-flex-->
                </v-layout>
                <v-layout
                  v-else
                  wrap
                >
                  <v-flex xs12>
                    <v-alert
                      :value="true"
                      outline
                      color="error"
                      icon="warning"
                    >
                      {{ props.item.msisdn }} is no longer available for reservation.
                    </v-alert>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        flat
        color="primary"
        @click.native="closeDialog"
      >
        Cancel
      </v-btn>
      <v-btn
        flat
        color="primary"
        @click.native="addMsisdnList"
      >
        Add New Numbers
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model="snackbar"
      :color="alertColor"
      :timeout="3000"
      top
    >
      {{ alertText }}
      <v-btn
        dark
        icon
        floating
        small
        flat
        @click="snackbar = false"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
// import { maxRegistrations } from '@/config'
import {
  newClass,
  newIcon,
  // spIcon,
  stringGen,
} from '@/utils'
import { createGetParams, createPostParams } from '@/rest'

export default {
  components: {
    BlackPackages: () => import(/* webpackChunkName: "blackPackages" */ '@/components/BlackPackages'),
    Iccid: () => import(/* webpackChunkName: "iccid" */ '@/components/Iccid'),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    selections: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      alertColor: 'error',
      alertText: '',
      expanded: [],
      headers: [
        {
          align: 'left',
          text: 'Msisdn',
          value: 'msisdn',
        },
        {
          text: 'Exclusive',
          value: 'exclusive',
        },
      ],
      iccidRules: [val => !!val || 'Iccid required!'],
      loading: false,
      localSelections: [],
      newClass: newClass,
      newIcon: newIcon,
      noDataText: "There's nothing to display.",
      noResultText: 'Msisdn list not available.',
      pagination: {
        descending: true,
        sortBy: 'msisdn',
      },
      search: '',
      selected: [],
      snackbar: false,
      token: stringGen(7),
    }
  },
  /* Disable max registration limit
  watch: {
    selected: function (val) {
      let count = val.length
      this.value.forEach(item => {
        if (item.icon === spIcon || item.icon === newIcon) count++
      })

      if (count === maxRegistrations) {
        this.alertColor = 'info'
        this.alertText = `Maximum number of SP + New number of ${maxRegistrations} reached.`
        this.snackbar = true
      } else if (count > maxRegistrations) {
        this.alertColor = 'warning'
        this.alertText = `Warning \u2014 Total SP + New Number exceeds ${maxRegistrations}!`
        this.snackbar = true
      }
    }
  },
  */
  mounted: function () {
    this.localSelections = this.selections
    const params = createGetParams({
      token: this.token,
    })
    this.$rest.get('getRegistrableSims.php', params).then(function (response) {
      response.data.forEach(simcard => {
        // const sellingPrice = simcard.price ? simcard.price : 0.00
        this.localSelections.push({
          msisdn: simcard.msisdn,
          iccid: simcard.iccid,
          // price: simcard.price,
          exclusive: simcard.exclusive,
          plan: {},
          icon: null,
          iconClass: null,
          donor: null,
          detail: null,
          // sellingPrice: sellingPrice,
          // chargeable: 0.00,
          source: null,
          stage: 0,
          value: false,
        })
        this.$emit('update:selections', this.localSelections)
      })
    }.bind(this)).catch(() => {})
  },
  methods: {
    addMsisdnList: function () {
      if (!this.selected.length) {
        this.alertColor = 'error'
        this.alertText = 'Error \u2014 No selection made!'
        this.snackbar = true
        return
      }

      /*
      let count = this.selected.length
      this.value.forEach(item => {
        if (item.icon === spIcon || item.icon === newIcon) count++
      })

      if (count > maxRegistrations) {
        this.alertColor = 'error'
        this.alertText = `Error \u2014 Total SP + New Number exceeds ${maxRegistrations}!`
        this.snackbar = true
        return
      }
      */

      const incomplete = this.selected.filter(item => {
        if (!item.iccid || item.iccid.length < 18) return true
        if (!item.plan || !item.plan.code) return true
        return false
      })
      let incompleteMsisdn = ''
      for (const item of incomplete) {
        if (incompleteMsisdn) incompleteMsisdn += ', '
        incompleteMsisdn += item.msisdn
      }

      if (incompleteMsisdn) {
        this.alertColor = 'error'
        this.alertText = 'Error \u2014 Incomplete details of ' + incompleteMsisdn
        this.snackbar = true
        return
      }

      this.selected.forEach(item => {
        // Remove from cache
        let index = 0
        for (const selection of this.localSelections) {
          if (selection.msisdn === item.msisdn) break
          index++
        }
        this.localSelections.splice(index, 1)
        this.$emit('update:selections', this.localSelections)

        item.detail = item.msisdn + ', ' + item.plan.code
        item.icon = newIcon
        item.iconClass = newClass
        // item.chargeable = Number(item.sellingPrice) + getNumeralFromBlackCode(item.plan.code)

        const localValue = this.value
        localValue.push(Object.assign({}, item))
        this.$emit('input', localValue)
      })

      this.selected.splice(0, this.selected.length)
      this.closeDialog()
    },
    changeSort: function (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    closeDialog: function () {
      this.$emit('update:dialog', false)
    },
    exclusiveIcon: function (exclusive) {
      return exclusive ? 'mdi-check' : ''
    },
    lockMsisdn: function (props) {
      const params = createPostParams({
        msisdn: props.item.msisdn,
        lock: props.expanded,
        token: this.token,
      })

      if (props.item.source) props.item.source.cancel('Force cancel.')
      props.item.source = this.$rest.CancelToken.source()
      Object.assign(params, { cancelToken: props.item.source.token })

      this.$rest.post('lockMsisdn.php', params)
        .then(() => {
          props.item.stage = 1
        }).catch(e => {
          props.item.stage = 2
        })
    },
  },
}
</script>

<!--style lang="stylus" scoped>
.theme--dark .inner {
    background-color: #424242;
}

.theme--light .inner {
    background-color: #f5f5f5;
}
</style-->
